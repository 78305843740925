<template>
  <!-- 批量改房价 -->
  <div>
    <el-dialog
      title="批量改价"
      :visible.sync="dialogFormVisible"
      top="10rem"
      :close-on-click-modal="false"
      width="1200px"
      @closed="cleanDataFn"
    >
      <div>
        <span style="margin-right: 20px">关联修改</span>
        <el-checkbox
          style="font-weight: 400; margin-right: 20px"
          :indeterminate="channelIndeterminate"
          v-model="ischeckAllChannel"
          @change="handleCheckAllChannelChange"
        >
          全选
        </el-checkbox>
        <el-checkbox-group
          v-model="checkedChannels"
          style="display: inline-block"
          @change="handleCheckedChannelsChange"
        >
          <el-checkbox
            v-for="item in channels"
            :label="item.id"
            :key="item.id"
            >{{ item.channel }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <div>
        <div class="contentbox">
          <el-row class="headbox">
            <el-col :span="5">
              <span style="margin-left: 32px"> 原房型 </span>
            </el-col>
            <el-col :span="5">适用日期</el-col>
            <el-col :span="9">适用星期</el-col>
            <el-col :span="2">价格</el-col>
            <el-col :span="3">利润公式</el-col>
          </el-row>
          <el-row class="headboxtwo">
            <el-col :span="5"
              ><el-checkbox
                :indeterminate="contentIndeterminate"
                v-model="ischeckAllContent"
                @change="handleCheckAllContentChange"
                style="margin-left: 8px"
                >全选</el-checkbox
              >
            </el-col>
            <el-col :span="5"
              ><el-date-picker
                size="mini"
                unlink-panels
                class="width200"
                style="width: 210px"
                :clearable="false"
                v-model="chooseDateAll"
                @change="changeDateAll"
                type="daterange"
                range-separator="-"
              >
                <!-- start-placeholder="开始日期"
                end-placeholder="结束日期" -->
              </el-date-picker></el-col
            >
            <el-col :span="9">
              <el-checkbox
                :indeterminate="weekIndeterminate"
                v-model="ischeckAllWeek"
                @change="handleCheckAllWeekChange"
              >
                全选
              </el-checkbox>
              <el-checkbox-group
                v-model="checkedWeeks"
                style="display: inline-block"
                @change="handleCheckedWeeksChange"
              >
                <el-checkbox
                  v-for="item in weeks"
                  :label="item.id"
                  :key="item.id"
                  >{{ item.week }}</el-checkbox
                >
                <!-- @change="handleQuanContentWeekChange(item, index, $event)" -->
              </el-checkbox-group>
            </el-col>
            <el-col :span="2"
              ><el-input
                size="mini"
                style="width: 80px"
                @input="setAllPrice"
                v-model="allPrice"
                placeholder="输入价格"
              >
              </el-input
            ></el-col>
            <el-col :span="3">
              <el-select
                size="mini"
                class="marginleft10"
                style="width: 60px"
                @change="setAllFormula"
                v-model="allFormula"
              >
                <el-option label="+" value="+"></el-option>
                <el-option label="-" value="-"></el-option>
                <el-option label="*" value="*"></el-option>
                <el-option label="÷" value="÷"></el-option>
                <el-option label="空" value=""></el-option>
              </el-select>
              <el-input
                size="mini"
                style="width: 70px; margin-left: 5px"
                v-model="allRatio"
                @input="setAllRatio"
                placeholder="输入价格"
              >
              </el-input>
            </el-col>
          </el-row>
          <!-- 详情 -->
          <el-row
            class="rowcontentbox"
            v-for="item in dataList"
            :key="item.roomId"
          >
            <el-col :span="5">
              <div
                style="
                  display: inline-block;
                  width: 25px;
                  vertical-align: top;
                  margin-left: 8px;
                "
              >
                <el-checkbox
                  @change="handleCheckContentChange(item, $event)"
                  v-model="item.selected"
                >
                </el-checkbox>
              </div>
              <div class="colname">
                {{ item.roomName }}({{ item.mealType }})
              </div>
            </el-col>
            <el-col :span="5">
              <el-date-picker
                size="mini"
                unlink-panels
                class="width200"
                style="width: 210px"
                :clearable="false"
                v-model="item.chooseTime"
                type="daterange"
                range-separator="-"
              >
                <!-- start-placeholder="开始日期"
                end-placeholder="结束日期" -->
              </el-date-picker>
            </el-col>
            <el-col :span="9" v-if="isTrue">
              <el-checkbox
                :indeterminate="item.weekIndeterminate"
                v-model="item.ischeckAllWeek"
                @change="handleCheckAllItemWeekChange(item, $event)"
              >
                全选
              </el-checkbox>
              <el-checkbox-group
                v-model="item.weeks"
                style="display: inline-block"
                @change="handleCheckedItemWeeksChange(item, $event)"
              >
                <el-checkbox
                  v-for="etem in weeks"
                  :label="etem.id"
                  :key="etem.id"
                  >{{ etem.week }}</el-checkbox
                >
              </el-checkbox-group>
            </el-col>
            <el-col :span="2"
              ><el-input
                size="mini"
                style="width: 80px"
                v-model="item.price"
                placeholder="输入价格"
              >
              </el-input
            ></el-col>
            <el-col :span="3">
              <el-select
                size="mini"
                class="marginleft10"
                style="width: 60px"
                v-model="item.formula"
              >
                <el-option label="+" value="+"></el-option>
                <el-option label="-" value="-"></el-option>
                <el-option label="*" value="*"></el-option>
                <el-option label="÷" value="÷"></el-option>
                <el-option label="空" value=""></el-option>
              </el-select>
              <el-input
                size="mini"
                style="width: 70px; margin-left: 5px"
                v-model="item.ratio"
                placeholder="输入价格"
              >
              </el-input>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="diafooterbtn">
        <el-button
          @click="cleanFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
         <el-button
          style="width: 84px"
          @click="handEditData1"
          size="small"
          type="success"
          >保存并继续</el-button
        >
        <el-button
          style="width: 84px"
          @click="handEditData"
          size="small"
          type="primary"
          >保存并关闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { batchUpRoomVestPrice_api } from "../../../apis/room";
export default {
  data() {
    return {
      arrRoomBigList: [],
      dialogFormVisible: false,
      ischeckAllChannel: false,
      channelIndeterminate: false,
      checkedChannels: [],
      chooseDateAll: "",
      weekIndeterminate: false,
      ischeckAllWeek: true,
      checkedWeeks: [1, 2, 3, 4, 5, 6, 0],
      contentIndeterminate: false,
      ischeckAllContent: true,
      allPrice: "",
      allFormula: "+",
      allRatio: "",
      weeks: [
        { week: "一", id: 1 },
        { week: "二", id: 2 },
        { week: "三", id: 3 },
        { week: "四", id: 4 },
        { week: "五", id: 5 },
        { week: "六", id: 6 },
        { week: "日", id: 0 },
      ],
      channels: [
        { channel: "艺龙", id: 1 },
        { channel: "飞猪", id: 2 },
        { channel: "美团", id: 3 },
        { channel: "去哪儿", id: 4 },
        { channel: "携程", id: 5 },
        { channel: "百度", id: 6 },
         { channel: "萝卜", id: 7 },
         { channel: "房仓天下", id: 8 },
      ],
      dataList: [
        {
          ischeckAllWeek: false,
          mealType: "",
          roomId: "",
          roomName: "",
          roomVestId: "",
          secondId: "",
          selected: "",
          week: "",
          weeks: [1, 2, 3, 4, 5, 6, 0],
          weekIndeterminate: "",
          formula: "+",
        },
      ],
      isTrue: true,
      isTrueContent: true,
      upData: {
        hotelSonId: "",
        platform: "",
        selectList: [],
      },
    };
  },
  methods: {
    open(hotelSonId, data) {
      this.upData = {
        hotelSonId: "",
        platform: "",
        selectList: [],
      };
      this.upData.hotelSonId = hotelSonId;
      console.log(data);
      //  console.log(this.dataList);
      data.forEach((item) => {
        this.$set(item, "chooseTime", [new Date(), new Date()]);
        this.$set(item, "ischeckAllWeek", true);
        this.$set(item, "weekIndeterminate", false);
        this.$set(item, "week", "");
        this.$set(item, "weeks", [1, 2, 3, 4, 5, 6, 0]);
        this.$set(item, "formula", "+");
        this.$set(item, "price", "");
        this.$set(item, "ratio", "");
      });
      this.dataList = data;
      this.dialogFormVisible = true;
    },
    //渠道全选
    handleCheckAllChannelChange(val) {
      let channelIds = [];
      this.channels.forEach((item) => {
        channelIds.push(item.id);
      });
      this.checkedChannels = val ? channelIds : [];
      this.channelIndeterminate = false;
    },
    //渠道单选
    handleCheckedChannelsChange(value) {
      let checkedCount = value.length;
      this.ischeckAllChannel = checkedCount === this.channels.length;
      this.channelIndeterminate =
        checkedCount > 0 && checkedCount < this.channels.length;
    },
    //全选下面的时间改变
    changeDateAll(val) {
      this.dataList.forEach((item) => {
        item.chooseTime = val;
      });
    },

    // 全选条件下周的全选
    handleCheckAllWeekChange(val) {
      let weekIds = [];
      this.weeks.forEach((item) => {
        weekIds.push(item.id);
      });

      this.checkedWeeks = val ? weekIds : [];
      this.weekIndeterminate = false;
      if (val) {
        this.dataList.forEach((ctem) => {
          ctem.weekIndeterminate = false;
          ctem.ischeckAllWeek = true;
          ctem.weeks = [1, 2, 3, 4, 5, 6, 0];
        });
      } else {
        this.dataList.forEach((ctem) => {
          ctem.weekIndeterminate = false;
          ctem.ischeckAllWeek = false;
          ctem.weeks = [];
        });
      }
    },
    //全选条件下 周的单选的值
    handleCheckedWeeksChange(value) {
      //  console.log(value);

      let checkedCount = value.length;
      this.ischeckAllWeek = checkedCount === this.weeks.length;
      this.weekIndeterminate =
        checkedCount > 0 && checkedCount < this.weeks.length;

      this.dataList.forEach((item) => {
        item.weeks = value;
        item.ischeckAllWeek = checkedCount === this.weeks.length;
        item.weekIndeterminate =
          checkedCount > 0 && checkedCount < this.weeks.length;
      });
    },
    //全选条件下， 周的单选
    // handleQuanContentWeekChange(weekItem, index, e) {
    //   console.log(weekItem);
    //   console.log(index);
    //   console.log(e);
    //   if (e) {
    //     this.dataList.forEach((ctem) => {
    //       if (ctem.weeks.indexOf(weekItem.id) == "-1") {
    //         ctem.weeks.push(weekItem.id);
    //         console.log(ctem.weeks);
    //       }
    //     });
    //   } else {
    //     this.dataList.forEach((ctem) => {
    //       if (ctem.weeks.indexOf(weekItem.id) != "-1") {
    //         ctem.weeks.splice(ctem.weeks.indexOf(weekItem.id), 1);
    //       }
    //     });
    //   }
    // },

    //单个item里面周的全选
    handleCheckAllItemWeekChange(item, val) {
      //   console.log("单个item里面周的全选", val);

      let weekIds = [];
      this.weeks.forEach((etem) => {
        weekIds.push(etem.id);
      });
      // if(val){
      //     this.$set(item,'weeks',[1,2,3,4,5,6,0])
      // }else{
      //   this.$set(item,'weeks',[])
      // }
      item.weeks = val ? weekIds : [];
      item.weekIndeterminate = false;
      //  this.isTrue=false
      // this.isTrue=true
    },
    //单个item里面周的单选
    handleCheckedItemWeeksChange(item, value) {
      console.log(value);

      // console.log(item);

      let checkedCount = value.length;
      item.ischeckAllWeek = checkedCount === this.weeks.length;
      item.weekIndeterminate =
        checkedCount > 0 && checkedCount < this.weeks.length;
      //   this.isTrueContent=false
      // this.isTrueContent=true
    },
    //列表全选
    handleCheckAllContentChange(e) {
      this.dataList.forEach((item) => {
        item.selected = e;
      });
      this.contentIndeterminate = false;
    },
    //列表单选
    handleCheckContentChange(item, e) {
      let selecteCount = 0;
      let unSelecteCount = 0;
      this.dataList.forEach((i) => {
        if (i.selected) {
          selecteCount++;
        } else {
          unSelecteCount++;
        }
      });
      if (selecteCount == this.dataList.length) {
        this.ischeckAllContent = true;
        this.contentIndeterminate = false;
      } else if (unSelecteCount == this.dataList.length) {
        this.ischeckAllContent = false;
        this.contentIndeterminate = false;
      } else {
        this.ischeckAllContent = false;
        this.contentIndeterminate = true;
      }
    },
    // 取消
    cleanFn() {
      this.dialogFormVisible = false;
    },
    // 提交
    async handEditData() {
      this.upData.platform = this.checkedChannels.join(",");
      this.dataList.forEach((item) => {
        item.week = item.weeks.join(",");
        item.startDate = this.initDate(item.chooseTime[0]);
        item.endDate = this.initDate(item.chooseTime[1]);

        if (item.selected) {
          //this.upData.selectList.push(item);
           this.upData.selectList.push({
            endDate:item.endDate,
            formula:item.formula,
            price:item.price,
            ratio:item.ratio,
            roomId:item.roomId,
            startDate:item.startDate,
            week:item.week,

          });
        }
      });
      let { code, data } = await batchUpRoomVestPrice_api(this.upData);
       this.upData.selectList=[]
      if (code == 0) {
        this.$message.success(data ? data : "提交成功");
        this.dialogFormVisible = false;
       
        this.$parent.getRoomInfoById();
      }
    },
    async handEditData1(){
       this.upData.platform = this.checkedChannels.join(",");
      this.dataList.forEach((item) => {
        item.week = item.weeks.join(",");
        item.startDate = this.initDate(item.chooseTime[0]);
        item.endDate = this.initDate(item.chooseTime[1]);

        if (item.selected) {
         // this.upData.selectList.push(item);
          this.upData.selectList.push({
            endDate:item.endDate,
            formula:item.formula,
            price:item.price,
            ratio:item.ratio,
            roomId:item.roomId,
            startDate:item.startDate,
            week:item.week,

          });
        }
      });
      let { code, data } = await batchUpRoomVestPrice_api(this.upData);
       this.upData.selectList=[]
      if (code == 0) {
        this.$message.success(data ? data : "提交成功");
       
        // this.dialogFormVisible = false;
        // this.$parent.getRoomInfoById();
      }

    },
    //全选值得改变
    setAllPrice(val) {
      this.dataList.forEach((item) => {
        item.price = val;
      });
    },
    setAllRatio(val) {
      this.dataList.forEach((item) => {
        item.ratio = val;
      });
    },
    setAllFormula(val) {
      this.dataList.forEach((item) => {
        item.formula = val;
      });
    },
    //格式化时间
    initDate(date) {
      date = new Date(date);

      let year = date.getFullYear();
      let mouth = date.getMonth() + 1;
      if (mouth < 10) {
        mouth = "0" + mouth;
      }
      let day = date.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      return `${year}-${mouth}-${day}`;
    },
    cleanDataFn() {
      Object.assign(this.$data, this.$options.data());
    },
  },
};
</script>

<style lang="less" scoped>
.erpboxone {
  padding-bottom: 20px;
}
.contentbox {
  /deep/ .el-checkbox {
    margin-right: 10px;
  }
}
.headbox {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #666666;
  height: 40px;
  line-height: 40px;
  background-color: #eeeeee;
  margin-top: 20px;
}
.headboxtwo {
  height: 40px;
  line-height: 40px;
  background-color: #f4f4f4;
}
.rowcontentbox {
  min-height: 60px;
  line-height: 60px;
  border: 1px solid #e2e9f4;
}
.colname {
  line-height: 30px;
  padding-right: 25px;
  display: inline-block;
  width: calc(100% - 60px);
  font-size: 12px;
}
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
</style>>
